import React, { useState, useEffect } from 'react';
import Axios from '../../services';


import LocationSearchInput from './../section/LocationSearchInput';

import Footer from './../Footer';
import Header from './../Header';

import "react-datetime/css/react-datetime.css";

import 'react-toastify/dist/ReactToastify.css';

import { useHistory } from "react-router-dom";
import Loader from './../section/Loader'


import avatar from './../assets/img/avatar.png';

import GoogleMapReact from 'google-map-react';
import Marker from './../section/Marker';
import useLocation from './Api/CurrentLocation';


export default function BarberDetails(props) {

    const history = useHistory()
    const [barber, setBarber] = useState([]);

    const [slotes, setSlote] = useState([]);

    const [service, setService] = useState([]);
    const [booking, setBooking] = useState([]);
    const [rating, setRating] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [barbers, setDeals] = useState([])

    const [location, setLocation] = useState({ address: '' });
    const [Load, setLoad] = useState(false);

    const current = useLocation();

    const [ajaxmessage, setMessage] = useState();
    const [errors, setError] = useState([]);



   const handleAjax = (event) => {
    Axios.post('api/customer/appointmentajax', { time: values.time, slote: values.slote })
        .then(res => {
            setMessage(res.data.Message);
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle the error here, you can set an error message state or perform any other action
        });
};


    const [values, setValues] = useState({
        address: '',
        slote: '',
        time: '',
        service: '',
        type: '',
        appType: '',
        barber_id: props.match.params.id,
    });

    const isSlotDisabled = (slotEndTime, currentDate) => {
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const slotEndHours = parseInt(slotEndTime.split(':')[0], 10);
        const slotEndMinutes = parseInt(slotEndTime.split(':')[1], 10);
    
        console.log(currentDate,values.time)
        // Compare slot date with current date
        if (currentDate !== values.time) {
            return false; // If the slot date is not today, return false to enable the slot
        }
    
        // If the slot date is today, compare the time to determine if it's past or not
        return currentHours > slotEndHours || (currentHours === slotEndHours && currentMinutes >= slotEndMinutes);
    };
    
    const getCurrentDate = () => {
        const today = new Date();
        let month = '' + (today.getMonth() + 1);
        let day = '' + today.getDate();
        const year = today.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    };
    


    const handleChange = (event) => {

        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        const data = {
            slote: values.slote,
            service: values.service,
            time: values.time,
            type: values.type,
            appType: values.appType,
            lat: location.address.lat,
            lng: location.address.lng,
            address: location.address.des,
            barber_id: props.match.params.id,
        };

        Axios.post('api/customer/appointment', data)
            .then(res => {
                setLoad(false)
                localStorage.setItem("appid", res.data.app_id);
                history.push('/payment')
            })
            .catch(res => {
                setLoad(false)
                setError(res.response.data.Error);
            });

    }


    useEffect(() => {
        fetchData();
        barberOnMap();
        return () => {
            fetchData();
            barberOnMap();
        }
    }, [Load, current]);

    useEffect((event) => {
        handleAjax(event);
        return () => {
            handleAjax(event);
        }
        }, [values.time]);
    // }, []);

    const barberOnMap = () => {

        Axios.post('api/customer/barbers', current)
            .then(res => {
                if (res && res.data && Array.isArray(res.data)) {
                    setDeals(res.data)
                }
            })

    }


    const fetchData = () => {
        Axios.get('api/customer/barberdetails/' + props.match.params.id)
            .then(res => {
                setLoad(false)
                setBarber(res.data.barber)
                setSlote(res.data.slots)
                setService(res.data.services)
                setRating(res.data.rating)
                setBooking(res.data.booking)
                setReviews(res.data.reviews)
            });
    }


    return (
        <>
            <Header />
            {Load ?

                <Loader />

                :
                <section id="team" className="team_section bd-bottom padding">
                    <div className="container">
                        <div className="section_heading text-center mb-40 mt-40 wow fadeInUp" data-wow-delay="300ms">
                            <h2>Barber Details</h2>
                            <div className="heading-line"></div>
                        </div>

                        <div className="row" style={{ boxShadow: "0px 0px 3px 1px #eee", padding: "20px" }}>
                            <div className="col-md-6 col-sm-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card p-3">
                                            <div className="d-flex align-items-center">
                                                <div className="image" style={{ borderRadius: "100px", boxShadow: "0px 0px 3px 1px #9e8a78" }}>
                                                    {
                                                        (barber.img == null) ?
                                                            // <img src={avatar} alt="Team Member" />
                                                            <img src={avatar} className="img-fluid" />
                                                            :
                                                            <img src={'https://hairwecut.co.uk/portal/barberDoc/' + barber.img} className="img-fluid rounded-circle" />
                                                        // <img src={'http://127.0.0.1:8000/portal/public/barber/'+barber.img} className="img-fluid rounded" /> 

                                                    }
                                                </div>
                                                <div className="ml-3 w-100">
                                                    <h4 className="mb-0 mt-0">{barber.salon}</h4> <span><small>{barber.address}</small>  </span>
                                                    <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                                        <div className="d-flex flex-column"> <span className="articles">Bookings</span> <span className="number1">{booking}</span> </div>
                                                        <div className="d-flex flex-column"> <span className="rating">Rating</span> <span className="number3">{rating}</span> </div>
                                                        <div className="d-flex flex-column"> <span className="followers">Reviews</span> <span className="number2">{reviews}</span> </div>
                                                    </div>
                                                    <div className="button mt-2 d-flex flex-row align-items-center">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="row ">

                                            <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        {/* <Datetime  timeFormat={false} isValidDate={disablePastDt} onChange={handleChange}   value={values.time}  name="time" style={{padding:"6px"}}  /> */}
                                                        <input
                                                            type="date"
                                                            onChange={handleChange}
                                                            value={values.time}
                                                            name="time"
                                                            className="form-control"
                                                            
                                                            min={getCurrentDate()}
                                                        />

                                                       
                                                        <span className="text-danger">{(errors.time == '') ? '' : errors.time}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <select className="form-control " style={{ padding: "6px" }} onChange={handleChange} onClick={handleAjax} value={values.slote} name="slote" >
                                                            <option>--Select Slot--</option>
                                                            {values.time && slotes.map((slote, i) => (
                                                                <option key={i} value={slote.id} disabled={isSlotDisabled(slote.to_time,  getCurrentDate())}>
                                                                    {slote.slot_no} | {slote.from_time} TO {slote.to_time}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {(ajaxmessage == 'Slot Is Avaliable') ?
                                                            <span className="text-success">{ajaxmessage}</span>
                                                            :
                                                            <span className="text-danger">{ajaxmessage}</span>
                                                        }
                                                        <span className="text-danger">{(errors.slote == '') ? '' : errors.slote}</span>
                                                    </div>
                                                </div>

                                               

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <select className="form-control nice type" onChange={handleChange} value={values.service} name="service">
                                                            <option value="">--Select Service--</option>

                                                            {
                                                                service.map((service, i) => (
                                                                    <option value={service.id} key={i}>{service.title}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span className="text-danger">{(errors.type == '') ? '' : errors.type}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <select className="form-control" name="type" onChange={handleChange} value={values.type}>
                                                            <option >--Service Type--</option>
                                                            <option value="Adult">Adult</option>
                                                            <option value="Child">Child</option>
                                                        </select>
                                                        <span className="text-danger">{(errors.service == '') ? '' : errors.service}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <select className="form-control" name="appType" onChange={handleChange} value={values.appType}>
                                                            <option >--Appointment Type--</option>
                                                            <option value="Barber_Shop">Barber Shop</option>
                                                            <option value="Mobile_Barber">Mobile Barber</option>
                                                        </select>
                                                        <span className="text-danger">{(errors.service == '') ? '' : errors.service}</span>
                                                    </div>
                                                </div>

                                                <div className={`col-md-12 d-none ${values.appType === 'Mobile_Barber' ? 'd-block' : 'd-none'}`}>
                                                    <div className="form-group" style={{ background: "#9e8a785c" }}>
                                                        <LocationSearchInput setLocation={setLocation} background='#fff' inputcolor='#000' textcolor='#fff' border='#eee' />
                                                    </div>
                                                    <span className="text-danger">{(errors.address == '') ? '' : errors.address}</span>
                                                </div>


                                                <button type="submit" className="btn btn-block makeappointment" disabled={!ajaxmessage || ajaxmessage !== 'Slot Is Avaliable'} >Make Appointment</button>

                                            </div>

                                        </form>

                                    </div>


                                </div>

                            </div>




                            <div className="col-md-6 col-sm-12">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyDVShzlNwwpUcswCgBEve7t6n9Q3eeD8PI' }}
                                    defaultCenter={{ lat: 0, lng: 0 }}
                                    center={{
                                        lat: current.lat,
                                        lng: current.lng
                                    }}
                                    defaultZoom={13}
                                    yesIWantToUseGoogleMapApiInternals
                                // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                >

                                    {barbers.map((barber, i) => (
                                        <Marker key={i} lat={barber.lat} lng={barber.lng} data={
                                            {
                                                name: barber.salon,
                                                // booking: (barber.appoitment[0] || '').booking || 0,
                                                rating: (barber.rating || {}).totalrating || 0,
                                                id: barber.user_id,
                                            }
                                        } />


                                    ))}

                                </GoogleMapReact>
                            </div>

                        </div>
                    </div>
                </section>
            }

            <Footer />
        </>
    )
}